<template>
  <div id="regions-view">
    <vs-popup classContent="popup-example" :title="$t('couriers')" :active.sync="showModal">
      <div class="">
        <vs-table v-if="currentPop === 'region'" hoverFlat ref="table" :data="carriers">
          <template slot="thead">
            <vs-th class="pr-0">{{ $t('fields.code') }}</vs-th>
            <vs-th class="pl-0">{{ $t('fields.name') }}</vs-th>
            <vs-th class="hidden lg:table-cell">{{ $t('fields.email') }}</vs-th>
            <vs-th class="hidden lg:table-cell">{{ $t('fields.phone') }}</vs-th>
            <vs-th>{{ $t('express') }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in carriers" :key="indextr" class="pt-3" :state="tr.isBloked ? 'danger' : ''">
              <vs-td class="pr-0">
                {{ data[indextr].code }}
                <!-- <feather-icon v-if="tr.isExpress" icon="StarIcon" svgClasses="w-5 h-5 text-warning" /> -->
              </vs-td>
              <vs-td class="pl-0" :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>
              <vs-td :data="data[indextr].email" class="hidden lg:table-cell">
                {{ data[indextr].email }}
              </vs-td>
              <vs-td :data="data[indextr].phone" class="hidden lg:table-cell">
                {{ data[indextr].phone }}
              </vs-td>
              <vs-td :data="data[indextr].isExpress">
                <vs-chip v-if="tr.isExpress !== undefined" :color="tr.isExpress ? 'primary' : 'danger'">
                  <feather-icon v-if="tr.isExpress" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <!-- <span v-if="tr.isExpress">{{tr.express_time}}10min</span> -->
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-table v-else hoverFlat ref="table" :data="cityRegions">
          <template slot="thead">
            <vs-th class="pr-0">#ID</vs-th>
            <vs-th class="pl-0">{{ $t('fields.title') }}</vs-th>
            <vs-th>{{ $t('express') }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in cityRegions" :key="indextr" class="pt-3" :state="tr.isBloked ? 'danger' : ''">
              <vs-td class="pr-0">
                {{ data[indextr].id }}
              </vs-td>
              <vs-td class="pl-0" :data="data[indextr].name">
                {{ data[indextr][`name_${$i18n.locale}`] }}
              </vs-td>
              <vs-td :data="data[indextr].isExpress">
                <vs-chip v-if="tr.isExpress !== undefined" :color="tr.isExpress ? 'primary' : 'danger'">
                  <feather-icon v-if="tr.isExpress" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        
      </div>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading">
      <vs-table hoverFlat ref="table" :data="data" search stripe pagination :max-items="itemsPerPage">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-no-wrap">
            <div class="flex flex-wrap-reverse items-center">
              <router-link v-if="$acl.check('admin')" :to="{name: 'regionCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
              </router-link>
            </div>
          </div>
          <div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mx-2">{{$t('sort')}}: {{ $t(current) }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="current='all'">
                  <span>{{ $t('all') }}</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="current='cities'">
                  <span>{{ $t('cities') }}</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="current='regions'">
                  <span>{{ $t('regions') }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ allItems.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : allItems.length }} of {{ queriedItems }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage=4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage=20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>

        <template slot="thead">
          <vs-th class="pr-0"></vs-th>
          <vs-th class="pl-0">{{ $t('fields.name_tm') }}</vs-th>
          <vs-th class="hidden lg:table-cell">{{ $t('fields.name_ru') }}</vs-th>
          <vs-th class="hidden lg:table-cell">{{ $t('fields.name_en') }}</vs-th>
          <vs-th>{{ $t('city') }}</vs-th>
          <vs-th>{{ $t('express') }}</vs-th>
          <vs-th>{{ $t('fields.actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
            <vs-td class="pr-0">
              <feather-icon v-if="tr.type === 'cities'" icon="StarIcon" svgClasses="w-5 h-5 text-warning" />
              <feather-icon v-else icon="MapPinIcon" svgClasses="w-5 h-5" />
            </vs-td>
            <vs-td class="pl-0" :data="data[indextr].name_tm">
              {{ data[indextr].name_tm }}
            </vs-td>
            <vs-td :data="data[indextr].name_ru" class="hidden lg:table-cell">
              {{ data[indextr].name_ru }}
            </vs-td>
            <vs-td :data="data[indextr].name_en" class="hidden lg:table-cell">
              {{ data[indextr].name_en }}
            </vs-td>
            <vs-td :data="getCity(data[indextr].cityId)">
              <template v-if="tr.type === 'regions'">{{ getCity(tr.cityId) }}</template>
            </vs-td>
            <vs-td :data="data[indextr].isExpress">
              <vs-chip v-if="tr.isExpress !== undefined" :color="tr.isExpress ? 'primary' : 'danger'">
                <!-- <feather-icon v-if="tr.isExpress" icon="CheckIcon" svgClasses="w-5 h-5" /> -->
                <span v-if="tr.isExpress">{{tr.express_time}}min</span>
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon v-if="tr.type === 'regions'" icon="TruckIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" title="View couriers" @click.stop="showData(tr.uuid, 'region')" />
              <feather-icon v-else icon="MenuIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" title="View regions" @click.stop="showData(tr.uuid, 'city')" />
              <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click.stop="editData(tr.uuid, tr.type)" />
              <!-- <feather-icon icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" title="Change banner status" @click.stop="changeStatus(tr.uuid, tr.type, tr.isActive)" /> -->
              <feather-icon v-if="$acl.check('admin')" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid, tr.type)" />
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      allItems: [],
      regions: [],
      cities: [],
      itemsPerPage: 15,
      current: 'all',
      showModal: false,
      carriers: [],
      cityRegions: [],
      currentPop: '',
      isLoading: true
    }
  },
  computed: {
    data () {
      switch (this.current) {
      case 'all': return this.allItems
      case 'cities': return this.cities
      case 'regions': return this.regions
      }
    },
    currentPage () { return this.isLoading ? 0 : this.$refs.table.currentx },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.data.length },
    getCity () {
      return id => {
        if (id) {
          const city = this.cities.find(city => city.id === id)
          return city[`name_${this.$i18n.locale}`]
        } else return '---'
      }
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.$http.get('/cities').then(response => {
        this.cities = response.data.map(el => { return { ...el, type: 'cities' } })
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      await this.$http.get('/regions').then(response => {
        this.regions = (response.data.data.map(el => { return { ...el, type: 'regions' } }))
        this.allItems = this.cities.concat(this.regions)
      })
      this.isLoading = false
    },
    showData (id, type) {
      this.currentPop = type
      if (type === 'region') {
        this.$http.get(`/regions/${id}`).then(response => {
          this.carriers = response.data.carriers
        })
      } else {
        this.$http.get(`/cities/${id}`).then(response => {
          this.cityRegions = response.data.regions
        })
      }
      this.showModal = true
    },
    editData (id, type) {
      if (type === 'regions') this.$router.push({name: 'regionEdit', params: { type, id }})
      else this.$router.push({name: 'regionEdit', params: { type, id }})
    },
    deleteData (id, type) {
      this.$http.delete(`/${type}/delete/${id}`).then(response => {
        if (response.status === 200) {
          this.$vs.notify({
            title: 'Success',
            text: 'Region successfully deleted!',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'primary'
          })
          this.fetchData()
        }
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#regions-view {
  .vs-con-table {

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
}
</style>